import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ImageList,
  ImageListItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams, useNavigate } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase.js";

import { doc, getFirestore, updateDoc, getDoc } from "firebase/firestore";

function ProductImages() {
  let params = useParams();
  let navigate = useNavigate();
  let [data, setData] = useState(null);
  let [images, setImages] = useState(null);

  useEffect(() => {
    getDoc(doc(getFirestore(), "products", params.productId)).then(
      (docSnap) => {
        setData(docSnap.data());
        setImages(docSnap.data().images);
      }
    );
  }, [params.productId]);

  const onClose = () => {
    navigate("/products");
  };

  // State to store uploaded file
  const [file, setFile] = useState("");

  // progress
  const [percent, setPercent] = useState(0);

  // Handle file upload event and update state
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleUpload = () => {
    if (!file) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(storage, `images/${file.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          images = { ...images, url };
          data.images = images;
          updateDoc(
            doc(getFirestore(), "products", params.productId),
            data
          ).then(() => {
            setData(data);
            setImages(images);
          });
        });
      }
    );
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        All info
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table size="small" sx={{ width: 600 }}>
          <TableBody>
            {data &&
              Object.keys(data).map((label) => (
                <TableRow key={label}>
                  <TableCell>{label}</TableCell>
                  <TableCell>
                    <pre>{JSON.stringify(data[label])}</pre>
                  </TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell>
                <ImageList
                  sx={{ width: 500, height: 450 }}
                  cols={3}
                  rowHeight={164}
                >
                  {images &&
                    Object.values(images).map((item) => (
                      <ImageListItem key={item}>
                        <img
                          srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          src={`${item}?w=164&h=164&fit=crop&auto=format`}
                          alt={item}
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                </ImageList>
                <div>
                  <input
                    type="file"
                    onChange={handleChange}
                    accept="/image/*"
                  />
                  <button onClick={handleUpload}>Upload to Firebase</button>
                  <p>{percent} "% done"</p>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}

export default ProductImages;
