// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCcWK6GOW_GVVS0ugqUUAVtn2JImIa9L6A",
  authDomain: "nomnomnom-172a0.firebaseapp.com",
  projectId: "nomnomnom-172a0",
  storageBucket: "nomnomnom-172a0.appspot.com",
  messagingSenderId: "302930449930",
  appId: "1:302930449930:web:dad43ebe45b0d2a18771f3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;

// Firebase storage reference
export const storage = getStorage(app);