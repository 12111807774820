import {
    getFirestore,
    collection,
    getDocs,
    query,
    deleteDoc,
    doc,
    getDoc,
    orderBy,
    limit,
    onSnapshot,
    updateDoc, deleteField
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Card, CardContent, Checkbox
} from "@mui/material";
import Alert from '@mui/material/Alert';
import { Outlet, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router'
import MachineMenu from './MachineMenu'

function MachineScreens() {
    let params = useParams();
    const machineId = params.machineId;
    const [open, setOpen] = useState(false);
    const [screens, setScreens] = useState([]);
    const [screens_checked, setScreensChecked] = useState([]);
    const [machine, setMachine] = useState(null);

    const navigate = useNavigate();


    function Checkbox(screenid) {

        const handleChange = async () => {
            console.log("was" + machine.screensaver);

            if (machine.screensaver.includes(screenid)) {
                machine.screensaver.splice(machine.screensaver.indexOf(screenid), 1);

            } else {
                machine.screensaver.push(screenid);
            }

            console.log("updating: " + machine.id);
            console.log("to" + machine.screensaver);
            
            setMachine(machine);
            syncScreenStates();
            await updateDoc(doc(getFirestore(), "machines", machine.id), { screensaver: machine.screensaver }).then(
                () => {
                    console.log("done updating");
                }
            );

        };
        let checked = screens_checked[screenid];
        return (

            <div>  <input type="checkbox" onChange={handleChange} checked={checked} /> </div>

        );

    };
    let rawmachine = {};

    function fetchMachineData() {
        var sub = onSnapshot(doc(getFirestore(), "machines", machineId), (docSnap) => {
            rawmachine = docSnap.data();
            rawmachine.id = docSnap.id;
            setMachine(rawmachine);
            syncScreenStates();
        }
        );
    }
    let screenarray = [];
    function fetchScreens() {
        var sub = onSnapshot(query(
            collection(getFirestore(), "screens"),
            orderBy("created", "asc")
        ), async (querySnapshot) => {
            
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.id = doc.id;
                screenarray[doc.id] = data;
            });
            console.log(screenarray);
            setScreens(screenarray);
            console.log(screens);
            syncScreenStates();
        });
    }

    function syncScreenStates() {
        var screenstate = [];
        console.log(screenarray);
        if (rawmachine == null) {

            return;
        }
        for (let screen in screenarray) {
            console.log(screen);
            screenstate[screen] = false;
            if (rawmachine.screensaver != null && rawmachine.screensaver.includes(screen)) {
                screenstate[screen] = true;
                console.log("this one is true");
            }
            console.log(screenstate);

        }
        console.log("screenstate: ");
        console.log( screenstate);
        setScreensChecked(screenstate);
    }


    useEffect(() => {

        fetchMachineData();
        fetchScreens();

    }, [machineId]);

    function machineinfo() {

        return (
            <div>

                Location: {machine.location} <br />
                Name: {machine.name} <br />

            </div>

        );
    }

    return (
        <div>
            {MachineMenu(machine, machineId)}
            <Card>

                <CardContent>
                    {machine != null ? (

                        machineinfo()

                    ) : ""}
                    Please select the images that you want to activate for the screensaver on this machine.
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>

                                    <TableCell>Name</TableCell>
                                    <TableCell>Screen</TableCell>
                                    {/* <TableCell>Discount Price</TableCell> */}
                                    {/* <TableCell>Category</TableCell> */}
                                    <TableCell>Active</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {screens != null ? Object.values(screens).map((screen) => (
                                    <TableRow key={screen.id}>

                                        <TableCell>{screen.name}</TableCell>
                                        <TableCell><img
                                            width="100px"
                                            srcSet={`${screen['url']}?w=270&h=480&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${screen['url']}?w=270&h=480&fit=crop&auto=format`}
                                            alt={screen['name']}
                                            loading="lazy"
                                        /></TableCell>
                                        <TableCell>{Checkbox(screen.id)}
                                        </TableCell>


                                    </TableRow>
                                )) : ""}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </CardContent>
            </Card>




            <Outlet />

        </div>
    );
}

export default MachineScreens;
