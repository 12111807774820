import React from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { AppBar, Button, Container, Toolbar, Typography } from "@mui/material";
import { NavLink, Route, Routes } from "react-router-dom";

import Products from "./page/Products";
import Machines from "./page/Machines";
import NomNomNom from "./page/NomNomNom";
import ProductImages from "./page/ProductImages";
import EditProduct from "./page/EditProduct";
import CreateProduct from "./page/CreateProduct";
import MachineProducts from "./page/MachineProducts";
import CreateMachineProduct from "./page/CreateMachineProduct";
import EditMachineProduct from "./page/EditMachineProduct";
import MachineTransactions from "./page/MachineTransactions";
import MachineChannels from "./page/MachineChannels";
import { useNavigate } from "react-router-dom";
import MachineDetail from "./page/MachineDetail";
import MachineScreens from "./page/MachineScreens";
import CreateScreen from "./page/CreateScreen";
import Screens from "./page/Screens";
import MachineSettings from "./page/MachineSettings";


function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <br />
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".0rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              NOMNOMNOM
            </Typography>
            <Button component={NavLink} to="products" color="inherit">
              Products
            </Button>
            <Button component={NavLink} to="screens" color="inherit">
              Screens
            </Button>
            <Button component={NavLink} to="machines" color="inherit">
              Machines
            </Button>
          </Toolbar>
        </AppBar>
        <br />

        <Routes>
          <Route path="main" element={<NomNomNom />} />
          <Route path="screens" element={<Screens />}>
            {/* <Route path="images/:productId" element={<ProductImages />} />
            <Route path="edit/:productId" element={<EditProduct />} />*/}
            <Route path="create" element={<CreateScreen />} /> 
          </Route>

          <Route path="products" element={<Products />}>
            <Route path="images/:productId" element={<ProductImages />} />
            <Route path="edit/:productId" element={<EditProduct />} />
            <Route path="create" element={<CreateProduct />} />
          </Route>
          <Route path="machines" element={<Machines />}></Route>
          <Route path="machines/:machineId" element={<MachineDetail />}></Route>
          <Route path="machines/:machineId/transactions" element={<MachineTransactions />}></Route>
          <Route path="machines/:machineId/channels" element={<MachineChannels />}></Route>
          <Route path="machines/:machineId/screens" element={<MachineScreens />}></Route>
          <Route path="machines/:machineId/settings" element={<MachineSettings />}></Route>
          <Route
            path="machines/:machineId/products"
            element={<MachineProducts />}
          >
            <Route path="create" element={<CreateMachineProduct />} />
            
            <Route path="edit/:productId" element={<EditMachineProduct />} />
          </Route>
        </Routes>
      </Container>
    </LocalizationProvider>
  );
}

export default App;
