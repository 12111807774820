import {
  getFirestore,
  collection,
  query,
  doc,
  orderBy,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";

import { useEffect, useState } from "react";
import {
  
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TextField,
  Card,
  CardContent,
} from "@mui/material";

import { Outlet, useParams } from "react-router-dom";

import MachineMenu from "./MachineMenu";

function MachineSettings() {
  let params = useParams();
  const machineId = params.machineId;
  
  const [screens, setScreens] = useState([]);
  const [screens_checked, setScreensChecked] = useState([]);
  const [machine, setMachine] = useState(null);


  function Checkbox(screenid) {
    const handleChange = async () => {
      let checked = screens_checked[screenid];
      if (checked === true) {
        machine.overlay.screen = null;
        setMachine(machine);
        await updateDoc(doc(getFirestore(), "machines", machine.id), {
          overlay: machine.overlay,
        }).then(() => {
          console.log("done updating");
        });
      } else {
        machine.overlay.screen = screenid;
        setMachine(machine);
        await updateDoc(doc(getFirestore(), "machines", machine.id), {
          overlay: machine.overlay,
        }).then(() => {
          console.log("done updating");
        });
      }
    };
    let checked = screens_checked[screenid];
    return (
      <div>
        {" "}
        <input type="checkbox" onChange={handleChange} checked={checked} />{" "}
      </div>
    );
  }

  function fetchMachineData() {
    var sub = onSnapshot(
      doc(getFirestore(), "machines", machineId),
      (docSnap) => {
        let rawmachine = docSnap.data();
        rawmachine.id = docSnap.id;
        if (rawmachine.overlay == null) {
          rawmachine.overlay = {
            active: false,
            starttime: "0:00",
            startday: "sat",
            endtime: "23:59",
            endday: "sun",
          };
        }
        setMachine(rawmachine);
      }
    );
  }

  function fetchScreens() {
    let screenarray = [];
    var sub = onSnapshot(
      query(collection(getFirestore(), "screens"), orderBy("created", "asc")),
      async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          screenarray[doc.id] = data;
        });

        setScreens(screenarray);
      }
    );
  }

  function syncScreenStates() {
    var screenstate = [];
    if (machine == null) {
      return;
    }
    for (let screen in screens) {
      screenstate[screen] = false;
      if (machine.overlay != null && machine.overlay.screen == screen) {
        screenstate[screen] = true;
      }
    }
    setScreensChecked(screenstate);
  }


  useEffect(() => {
    syncScreenStates();
  }, [screens]);

  useEffect(() => {
    syncScreenStates();
  }, [machine]);

  
  useEffect(() => {
    fetchMachineData();
    fetchScreens();
  }, [machineId]);

  function machineinfo() {
    return (
      <div>
        Location: {machine.location} <br />
        Name: {machine.name} <br />
      </div>
    );
  }

  const handleActiveChange = async (e) => {
    console.log("was" + machine.overlay.active);
    machine.overlay.active = e.target.value;
    console.log("updating: " + machine.id);
    console.log("to" + machine.overlay.active);
    setMachine(machine);
    await updateDoc(doc(getFirestore(), "machines", machine.id), {
      overlay: machine.overlay,
    }).then(() => {
      console.log("done updating");
    });
  };

  const handleDayStartChange = async (e) => {
    console.log("was" + machine.overlay.startday);
    machine.overlay.startday = e.target.value;
    console.log("updating: " + machine.id);
    console.log("to" + machine.overlay.startday);
    setMachine(machine);
    await updateDoc(doc(getFirestore(), "machines", machine.id), {
      overlay: machine.overlay,
    }).then(() => {
      console.log("done updating");
    });
  };

  const handleTimeStartChange = async (e) => {
    console.log("was" + machine.overlay.starttime);
    machine.overlay.starttime = e;
    console.log("updating: " + machine.id);
    console.log("to" + machine.overlay.starttime);
    setMachine(machine);
    await updateDoc(doc(getFirestore(), "machines", machine.id), {
      overlay: machine.overlay,
    }).then(() => {
      console.log("done updating");
    });
  };

  const handleDayEndChange = async (e) => {
    console.log("was" + machine.overlay.endday);
    machine.overlay.endday = e.target.value;
    console.log("updating: " + machine.id);
    console.log("to" + machine.overlay.endday);
    setMachine(machine);
    await updateDoc(doc(getFirestore(), "machines", machine.id), {
      overlay: machine.overlay,
    }).then(() => {
      console.log("done updating");
    });
  };

  const handleEndTimeChange = async (e) => {
    console.log("was" + machine.overlay.endtime);
    machine.overlay.endtime = e;
    console.log("updating: " + machine.id);
    console.log("to" + machine.overlay.endtime);
    setMachine(machine);
    await updateDoc(doc(getFirestore(), "machines", machine.id), {
      overlay: machine.overlay,
    }).then(() => {
      console.log("done updating");
    });
  };

  

  const handleHeaderLineChange = async (e) => {
    console.log("was" + machine.header_line);
    machine.header_line = e;
    console.log("updating: " + machine.id);
    console.log("to" + machine.header_line);
    setMachine(machine);
    await updateDoc(doc(getFirestore(), "machines", machine.id), {
        header_line: machine.header_line,
    }).then(() => {
      console.log("done updating");
    });
  };

  return (
    <div>
      {MachineMenu(machine, machineId)}
      <Card>
        <CardContent>
          {machine != null ? machineinfo() : ""}
          <h1>Marketing header </h1>
          If you add a text in this textfield, then it will be shown on the machine instead of "good morning"
          <br />
          HeaderLine:
          <TextField
          value={machine?.header_line}
                      // defaultValue={machine?.header_line}
                      fullWidth="true"
                      label=""
                      name="headerLine"
                      onChange={(e) => {
                        handleHeaderLineChange(e.target.value);
                      }}
                    />
          <br />
          <br />
          <h1>Closed settings</h1>
          If active is set to True, then the machine will be closed between the start day + time and the end day + time.

          The selected image below will then be shown. It can take up to 15 minutes before a changed schedule is executed.
          {machine != null ? (
            <Table size="normal" sx={{ maxWidth: 600 }}>
              <TableBody>
                <TableRow>
                  <TableCell>Active</TableCell>
                  <TableCell>
                    <Select
                      defaultValue={machine.overlay.active}
                      style={{ width: "200px" }}
                      name="active"
                      required
                      onChange={(e) => {
                        handleActiveChange(e);
                      }}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Startday</TableCell>
                  <TableCell>
                    <Select
                      defaultValue={machine.overlay.startday}
                      style={{ width: "200px" }}
                      name="startDay"
                      required
                      onChange={(e) => {
                        handleDayStartChange(e);
                      }}
                    >
                      <MenuItem value="mon">
                        <em>Monday</em>
                      </MenuItem>
                      <MenuItem value="tue">
                        <em>Tuesday</em>
                      </MenuItem>
                      <MenuItem value="wed">
                        <em>Wednesday</em>
                      </MenuItem>
                      <MenuItem value="thu">
                        <em>Thursday</em>
                      </MenuItem>
                      <MenuItem value="fri">
                        <em>Friday</em>
                      </MenuItem>
                      <MenuItem value="sat">
                        <em>Saturday</em>
                      </MenuItem>
                      <MenuItem value="sun">
                        <em>Sunday</em>
                      </MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>StartTime</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={machine.overlay.starttime}
                      label="startTime"
                      name="startTime"
                      onChange={(e) => {
                        handleTimeStartChange(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Endday</TableCell>
                  <TableCell>
                    <Select
                      defaultValue={machine.overlay.endday}
                      style={{ width: "200px" }}
                      name="endDay"
                      required
                      onChange={(e) => {
                        handleDayEndChange(e);
                      }}
                    >
                      <MenuItem value="mon">
                        <em>Monday</em>
                      </MenuItem>
                      <MenuItem value="tue">
                        <em>Tuesday</em>
                      </MenuItem>
                      <MenuItem value="wed">
                        <em>Wednesday</em>
                      </MenuItem>
                      <MenuItem value="thu">
                        <em>Thursday</em>
                      </MenuItem>
                      <MenuItem value="fri">
                        <em>Friday</em>
                      </MenuItem>
                      <MenuItem value="sat">
                        <em>Saturday</em>
                      </MenuItem>
                      <MenuItem value="sun">
                        <em>Sunday</em>
                      </MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>EndTime</TableCell>
                  <TableCell>
                    <TextField
                      defaultValue={machine.overlay.endtime}
                      label="endTime"
                      name="endTime"
                      onChange={(e) => {
                        handleEndTimeChange(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            ""
          )}
          <br />
          <br />
          Please select one! image that you want to activate as overlay during
          the closed time of the machine.
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Screen</TableCell>
                  {/* <TableCell>Discount Price</TableCell> */}
                  {/* <TableCell>Category</TableCell> */}
                  <TableCell>Select</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {screens != null
                  ? Object.values(screens).map((screen) => (
                      <TableRow key={screen.id}>
                        <TableCell>{screen.name}</TableCell>
                        <TableCell>
                          <img
                            width="100px"
                            srcSet={`${screen["url"]}?w=270&h=480&fit=crop&auto=format&dpr=2 2x`}
                            src={`${screen["url"]}?w=270&h=480&fit=crop&auto=format`}
                            alt={screen["name"]}
                            loading="lazy"
                          />
                        </TableCell>
                        <TableCell>{Checkbox(screen.id)}</TableCell>
                      </TableRow>
                    ))
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Outlet />
    </div>
  );
}

export default MachineSettings;
