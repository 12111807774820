import {
  getFirestore,
  collection,
  getDocs,
  query,
  deleteDoc,
  doc,
  getDoc,
  orderBy,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router'
import Alert from '@mui/material/Alert';
import MachineMenu from './MachineMenu'


function MachineChannels() {
  let params = useParams();
  const machineId = params.machineId;
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [channels, setChannels] = useState({});
  const [productNames, setProductNames] = useState(null);
  const navigate = useNavigate();
  const [machine, setMachine] = useState(null);
  const location = useLocation();

  const handleClose = () => {
    setOpen(false);
  };

  function fetchData() {
    getDocs(
      query(collection(getFirestore(), `machines/${machineId}/channels`))
    ).then(async (querySnapshot) => {
      let channels = {};

      // Map each product to a promise that resolves to the name
      const namePromises = querySnapshot.docs.map(async (doc) => {
        let data = doc.data();
        data.id = doc.id;
        if(data.status !="Not connected")
        {
          if(data.status != "In service with product"){
            data.warning = true;
            }
            channels[doc.id] = data;
        }
        return;
      });

      // Wait for all promises to resolve
      const resolvedNames = await Promise.all(namePromises);

      // Update the state with the resolved names
      setChannels(channels);
      
    });
  }


  function fetchMachineData() {

        
    getDoc(doc(getFirestore(), "machines", machineId)).then(
        (docSnap) => {
            let machine = docSnap.data();
            
          setMachine(docSnap.data());
        }
      );



   
    
      
  }
  useEffect(() => {
    fetchData();
  }, [location.key])

  useEffect(() => {
    fetchData()
    fetchMachineData();
  }, [machineId]);


  return (
    <div>
              {MachineMenu(machine, machineId)}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={20}></TableCell>
              <TableCell>ID</TableCell> 
              <TableCell>TRAY</TableCell>
              <TableCell>CHANNEL</TableCell>
              <TableCell>Status</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(channels).map((channel, index) => (
                  <TableRow key={channel.id}>
                    <TableCell>{channel.warning?<Alert severity="warning"></Alert>:''}</TableCell>
                    {/* <TableCell>{product.id}</TableCell>
                    <TableCell>
                      {product.reference
                        ? product.reference.path
                        : "No reference"}
                    </TableCell> */}
                    <TableCell>
                      {channel.id}
                      
                    </TableCell>
                    <TableCell>
                      {channel.tray}
                    </TableCell>
                    <TableCell>{channel.channel}</TableCell>
                    <TableCell>
                    {channel.status}
                    </TableCell>
                    
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Outlet />
     
    </div>
  );
}

export default MachineChannels;
