import {
  getFirestore,
  collection,
  getDocs,
  query,
  deleteDoc,
  doc,
  orderBy,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router'


function Products() {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const handleSearch = (event) => {
    
      setSearch(event.target.value);
   
  };

  const handleClose = () => {
    setOpen(false);
  };

  const location = useLocation()

 
  function fetchData()
  {
    getDocs(query(collection(getFirestore(), "products"),orderBy("name"))).then(
      (querySnapshot) => {
        let products = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          products[doc.id] = data;
        });
 
        setProducts(products);
        
      }
    );
  }

  useEffect(() => {
    fetchData();
  }, [location.key])

  useEffect(() => {
    fetchData();
  }, []);

  const onDelete = async (id) => {
    await deleteDoc(doc(getFirestore(), "products", id));
    setProducts((prev) => {
      let newProducts = { ...prev };
      delete newProducts[id];
      return newProducts;
    });

    

    // Delete product from all machines
    const machines = await getDocs(
      query(collection(getFirestore(), "machines"))
    );
    machines.forEach(async (machine) => {
      const productsCol = collection(
        getFirestore(),
        `machines/${machine.id}/products`
      );
      const products = await getDocs(productsCol);
      products.forEach(async (product) => {
        if (product.id === id) {
          await deleteDoc(doc(productsCol, product.id));
        }
      });
    });
  };

  return (
    <div>
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => navigate("create")}
        variant="contained"
      >
        Add product
      </Button>
      <p style={{ marginBottom: "20px", alignSelf:"right" }} variant="contained">
      <label  htmlFor="search">
        Search:
        <input id="search" type="text" onChange={handleSearch} />
      </label>
      </p>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Base Price</TableCell>
              {/* <TableCell>Discount Price</TableCell> */}
              {/* <TableCell>Category</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
           
            {Object.values(products).filter(product=>product.name.toLowerCase().includes(search.toLowerCase())).map((product) => (
              <TableRow key={product.id}>
                <TableCell>{product.id}</TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>&euro;{product.base_price}</TableCell>
                {/* <TableCell>&euro;{product.discount_price}</TableCell> */}
                {/* <TableCell>{product.category}</TableCell> */}
                <TableCell>
                  <Chip
                    style={{ marginRight: "10px" }}
                    label="Edit"
                    onClick={() => navigate(`edit/${product.id}`)}
                  />
                  {/* <Chip
                    label="Delete"
                    onClick={() => {
                      setDeleteId(product.id);
                      setOpen(true);
                    }}
                  /> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Outlet />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              onDelete(deleteId);
              handleClose();
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Products;
